import React, { useState, useEffect } from "react";
import "../../General/general.css";
import "./header.css";
import logo from "../Pictures/Logo_nose.png";

export default function Header(props) {

  useEffect(() => {}, []);

  return (    
      <div className="headerTop"> 
          <nav className="navbar navbar-expand-lg"> 
          <a href="/">
            <img src={logo} className="logo" alt="Goddys logo" width="210" />
          </a>      
          <div className="container-fluid pe-0 desktopView">          
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarScroll"
              aria-controls="navbarScroll"
              aria-expanded="false"
              aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarScroll">
              <ul className="navbar-nav ms-auto my-2 my-lg-0 navbar-nav-scroll">
                <li className="nav-item">
                  <a className="nav-link" href="/">
                    Home
                  </a>
                </li> 
                <li className="nav-item">
                  <a className="nav-link" href="/projects">
                    Projects & Skills
                  </a>
                </li> 
                <li className="nav-item">
                  <a className="nav-link" href="/news">
                    News
                  </a>
                </li> 
                <li className="nav-item">
                  <a className="nav-link" href="/about">
                    About MR
                  </a>
                </li> 
                <li className="nav-item">
                  <a className="nav-link" href="/contact">
                    Contact Us
                  </a>
                </li> 
              </ul>
            </div>           
          </div>
              
          <div className="mobileView">          
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarScroll"
              aria-controls="navbarScroll"
              aria-expanded="false"
              aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarScroll">
              <ul className="navbar-nav ms-auto my-2 my-lg-0 navbar-nav-scroll">
                <li className="nav-item">
                  <a className="nav-link" href="/">
                    Home
                  </a>
                </li> 
                <li className="nav-item">
                  <a className="nav-link" href="/projects">
                    Projects & Skills
                  </a>
                </li> 
                <li className="nav-item">
                  <a className="nav-link" href="/news">
                    News
                  </a>
                </li> 
                <li className="nav-item">
                  <a className="nav-link" href="/about">
                    About MR
                  </a>
                </li> 
                <li className="nav-item">
                  <a className="nav-link" href="/contact">
                    Contact Us
                  </a>
                </li> 
              </ul>
            </div>           
          </div>
        </nav>
    </div>
  );
}
