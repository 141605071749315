import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./aboutUs.css";
import axios from "axios";

export default function AboutUs() {
  const params = useParams();

  // Project Variables
  const currentPageId = 46;
  const [loadingContents, setLoadingContents] = useState(true);
  const [contents, setContents] = useState([]);  
  const [loadingImages, setLoadingImages] = useState([]);
  const [images, setImages] = useState([]);
  
  useEffect(() => {
    getContents();
    getMedia();
  }, []);

  async function getContents() {
    if (loadingContents) {
      const returnInfo = await axios.get("https://wp.mrcdd.se/wp-json/wp/v2/specialpages?per_page=100");
      await setContents(returnInfo.data);
      setLoadingContents(false);
    }
  }

  async function getMedia() {
    if (loadingImages) {
      const returnInfo = await axios.get(
        "https://wp.mrcdd.se/wp-json/wp/v2/media?per_page=100"
      );
      await setImages(returnInfo.data);
      setLoadingImages(false);
    }    
  }

  function showNewsEntry(contentId) {
    // Loop through the news in the "ProjectParameter" parameter
    const curContentEntry = contents.filter(x=>Number(x.id)===Number(contentId))[0];    
    const curContentEntryPicture = loadingImages ? "" : images.filter(x=>Number(x.id)===Number(curContentEntry.acf.picture))[0].source_url;
    
    if (curContentEntry) {
      const a = curContentEntry.acf.content.replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br/>$2');
      return <div className="col-12">
                <div className="row">
                  <div className="col-sm-12 col-lg-9"><img className="newshow-image" id="mainnewshowImage" alt="" src={curContentEntryPicture} /></div>
                  <div className="col-sm-12 col-lg-3">
                    <h3 className="">{curContentEntry.title.rendered}</h3>
                    <span className="newsEntryContent" dangerouslySetInnerHTML={{__html: a}}></span>      
                  </div>
                </div>
              </div>
      }    
  }

  return (
    <div> 
        <div className="row">
          {loadingContents ? "Loading news entry..." : showNewsEntry(currentPageId)}       
        </div>
        <br/>
    </div>
  );
}
