import React, { useEffect } from "react";
import "./App.css";
import Header from "./Components/General/Header/header";
import "./Components/General/general.css";
import Home from "./Components/Home/home";
import Projects from "./Components/Projects/projects";
import News from "./Components/News/news";
import About from "./Components/AboutUs/aboutUs";
import Contact from "./Components/ContactUs/contactUs";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

export default function App() {

  useEffect(() => {});

  return (
    <div className="App">
      <header className="App-header">
        <Header></Header>
      </header>
      <Router>
        <Routes>
          <Route
            path="/projects/"
            element={<Projects />}
          />
          <Route
            path="/projects/project/:projectId"
            element={<Projects />}
          />
          <Route
            path="/news/"
            element={<News />}
          />
          <Route
            path="/news/:newsId"
            element={<News />}
          />  
          <Route
            path="/projects/category/:projectCategoryId"
            element={<Projects />}
          />           
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </Router>
    </div>
  );
}
