import React from "react";
import "./home.css";

export default function Home() {
  return (
    <div>
      <div className="hero"> </div>      
    </div>
  );
}
